#bt-bot-line {
  opacity: 0.7;
}

#bt-middle-table {
  /*
    max padding (20px) at 300px and min pading (0px) at 200px
    20px = (300px - 200px) * 0.2 = 100px * 0.2 = 20px
    0px = (200px - 200px) * 0.2
  */
  --padding-height: calc(max(0px, min(20px, (100vh - 200px) * 0.2)));
  --padding-width: calc(max(0px, min(20px, (100vw - 200px) * 0.2)));
  padding-top: var(--padding-height);
  padding-bottom: var(--padding-height);
  padding-left: var(--padding-width);
  padding-right: var(--padding-width);
  box-sizing: border-box;
}
