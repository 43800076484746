#more_panel {
  --input-margin: 12px;
}

/* checkbox styling */
input[type="checkbox"] {
  background: #292a2d;
  border: solid 1px #5e6064;
  height: 16px;
  width: 28px;
  position: relative;
  /*vertical-align: top;*/
  margin-right: 3px;
  -webkit-appearance: none;
  -webkit-border-radius: 3px;
  -webkit-transition: all 0.5s;
}
input[type="checkbox"]:after {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  background: #91949d;
  position: absolute;
  top: 1px;
  left: 13px;
  -webkit-border-radius: 2px;
  -webkit-transition: all 0.5s;
}
input[type="checkbox"]:checked {
  border: solid 1px #5ba4d5;
  box-shadow: 0 0 5px #5ba4d5;
}
input[type="checkbox"]:checked:after {
  background: #5ba4d5;
  left: 1px;
}

label {
  margin-right: var(--input-margin);
  white-space: nowrap;
  display: inline-block;
}
label > * {
  margin: 2px;
}

label.pointer-cursor,
label.pointer-cursor > * {
  cursor: pointer !important;
}

/* vertical align options*/
label:before {
  content: " ";
  height: 100%;
  width: 0;
}
label:before,
label > * {
  display: inline-block;
  vertical-align: middle;
}

/* color input playground: http://jsfiddle.net/BC7JD/2/ */
input[type="color"] {
  padding: 0px;
  height: 16px;
  outline: 0;
  border: 1px solid #888;
  border-radius: 3px;
}

#share-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#share-container.no-share-link #share-content {
  display: none;
}
#share-container:not(.no-share-link) #share-preamble {
  display: none;
}

.action-button {
  margin-right: var(--input-margin);
}
