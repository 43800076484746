.vertical-slot-wrapper {
  /*
  outline: 1px solid var(--ad-border-color);
  */
  margin-left: auto;
  margin-right: auto;
}
.slot-left {
  display: flex;
  flex-direction: column;
}
.slot-left ~ .slot-left {
  margin-top: 10px;
}
#ads-left {
  position: relative;
  z-index: 999999;
}
#ads-left:before {
  position: absolute;
  z-index: -1;
  top: var(--header-height);
  left: 0;
}
#ads-left:before {
  content: "\A A \A d \A v \A e \A r \A t";
  width: 100%;
  height: 100vh;
  font-size: 1.44em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  white-space: pre;
}
.vertical-slot-wrapper {
  overflow: hidden;
  max-width: 160px;
}
.slot-left .vertical-slot-wrapper .adsbygoogle {
  display: block;
  width: 160px;
  height: 600px;
}

html {
  --hide-left-ad: 0;
}
html:not(.show-ads) {
  --hide-left-ad: 1;
}

#fv_left-side {
  --width: 180px;
  width: var(--width);

  --margin-left: calc(var(--hide-left-ad) * -1 * var(--width));
  margin-left: var(--margin-left);
  transition: margin-left 0.7s ease-in-out;
}

#left-donate-icon,
#left-donate-text {
  vertical-align: middle;
  display: inline-block;
}
#left-donate-icon {
  background-image: url(/donate.hash_a0d107d677d1a39459f87c46a11a2179.svg);
  height: 22px;
  width: 22px;
}
#left-donate-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 700px) {
  .slot-left .vertical-slot-wrapper .adsbygoogle {
    display: none !important;
  }
  html {
    --hide-left-ad: 1;
  }
}

.custom-banner #custom-banner {
  cursor: pointer;
}
