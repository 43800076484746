.slot-BTF_2 {
  display: block;
}

.slot_btf_container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
