/* layout */
@media (max-width: 729px) {
  :root {
    --ad-width: 320px;
    --ad-height: 50px;
  }
}
@media (min-width: 730px) {
  :root {
    --ad-width: 728px;
    --ad-height: 90px;
  }
}
:root {
  --ad-remover-height: 24px;
  --ad-border-width: 1px;
}

.horizontal-slot-wrapper,
.horizontal-slot-wrapper .adsbygoogle {
  display: block;
  min-height: 20px;
  max-height: var(--ad-height);
  min-width: 20px;
  max-width: var(--ad-width);
  width: 100vw;
  height: 100vh;
}
.horizontal-slot-wrapper {
  /* avoids creeping out of ads when the user downsizes his browser */
  overflow: hidden;
}
.horizontal-slot-wrapper {
  margin: auto;
  display: block;
}

/*
.horizontal-slot-wrapper .adsbygoogle {
  width: 550px;
  height: 76px;
  background: red;
}
*/

/* function */

html.no-ads .advi-wrap,
html.no-ads .slot-left,
html.no-ads .slot-BTF_2 {
  display: none;
}

/* style */

:root {
  --ad-border-color: rgba(200, 200, 200, 0.7);
}

/* ad gray border */
.horizontal-slot-wrapper {
  border: var(--ad-border-width) solid var(--ad-border-color);
  border-top-width: 0;
  box-sizing: border-box !important;
}
