#background-area-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
}
#background-area {
  position: absolute;
  top: 0;
  left: 0;
  /*
  width: 100%;
  */
  width: 100vw;
  height: 100vh;
}
#background-area {
  background-repeat: no-repeat;
  background-position: center;
}
#background-area.show-load-indicator::after {
  content: "";
  display: block;

  background-image: url(/load_indicator.hash_c1ccd88e5782c1936f294ad043aa3b73.svg);

  position: absolute;
  width: 100%;
  height: 100%;

  background-size: 50px;

  background-repeat: no-repeat;
  background-position: center;

  pointer-events: none;
}
